import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { getBlogUrl } from "../../lib/helpers"
import styles from "../../styles/blog-post-preview.module.css"

function BlogPostPreview(props) {
  return (
    <div className={styles.slidewrapper} key={props.id}>
      <Link
        className={styles.root}
        to={`/${getBlogUrl(props.parenttag.slug.current, props.slug.current, props.articleTier)}`}
      >
        <div className={styles.leadMediaThumb}>
          {props.mainImage && props.mainImage.asset && (
            <Img
              fluid={props.mainImage.asset.fluid}
              className={styles.previewimage}
              alt={props.mainImage.alt}
            />
          )}
        </div>
        <h3 className={styles.title}>{props.title}</h3>
        <div className={styles.readmore}>
          <p className={styles.author}>
            von {props._rawAuthors.name ? props._rawAuthors.name : "Unknown"}
          </p>
          <p className={styles.weiterMitArrow}>
            WEITERLESEN <span className={styles.arrow}>→</span>
          </p>
        </div>
      </Link>
    </div>
  )
}

export default BlogPostPreview
