import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { getBlogUrl } from "../../lib/helpers"
import BlockText from "./block-text"
import styles from "../../styles/big-post-preview.module.css"

function BlogPostPreview(props) {
  return (
    <div>
      <Link
        className={styles.root}
        to={`/${getBlogUrl(props.parenttag.slug.current, props.slug.current, props.articleTier)}`}
      >
        <div className={styles.leadMediaThumb}>
          {props.mainImage && props.mainImage.asset && (
            <Img
              fluid={props.mainImage.asset.fluid}
              className={styles.teststyle}
              alt={props.mainImage.alt}
            />
          )}
        </div>
        <div className={styles.textarea}>
          <h3 className={styles.title}>{props.title}</h3>
          {props._rawExcerpt && (
            <div className={styles.excerpt}>
              <BlockText blocks={props._rawExcerpt} />
            </div>
          )}
          <div className={styles.readmore}>
            <p>
              WEITERLESEN <span className={styles.arrow}>→</span>
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default BlogPostPreview
