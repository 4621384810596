import React from "react"
import Slider from "react-slick"
import BigPostPreview from "./big-post-preview"
import BlogPostPreview from "./blog-post-preview"
import styles from "../../styles/blog-post-preview-grid.module.css"
import RightArrowLogo from "../../assets/right-arrow.svg"

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <div className={className} style={{ ...style, display: "none" }} />
      <img
        src={RightArrowLogo}
        alt="arrow_right"
        style={{ width: "30px", height: "38px", position: "absolute", top: "25%", right: "-1.5%" }}
      />
    </div>
  )
}

const PrevArrow = props => <div style={{ ...props.style, display: "none" }} />

class BlogPostPreviewGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTouchDevice: false
    }
  }

  // Check if the device is a touch-device - if yes, add a listener
  componentDidMount() {
    if (window && "ontouchstart" in window) {
      this.setState({ isTouchDevice: true })
      window.addEventListener("touchstart", this.touchStart)
      window.addEventListener("touchmove", this.preventTouch, {
        passive: false
      })
    }
  }

  // Check if the device is a touch-device - if yes, clean up listener and stuff
  componentWillUnmount() {
    if (window && "ontouchstart" in window) {
      window.removeEventListener("touchstart", this.touchStart)
      window.removeEventListener("touchmove", this.preventTouch, {
        passive: false
      })
    }
  }

  // Find the initial point of touch
  touchStart(e) {
    this.firstClientX = e.touches[0].clientX
    this.firstClientY = e.touches[0].clientY
  }

  // Prevent scroll if the horizontal gesture dominates the vertical gesture,
  // or when the horizontal gesture exceeds some threshold
  preventTouch(e) {
    const minValue = 5 // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX
    this.clientY = e.touches[0].clientY - this.firstClientY

    if (Math.abs(this.clientX) > minValue || Math.abs(this.clientX) > Math.abs(this.clientY)) {
      e.preventDefault()
      e.returnValue = false
      return false
    }
  }

  render() {
    const { nodes } = this.props
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      draggable: true,
      accessibility: true,
      className: "innerslide"
    }
    const mobilesettings = {
      dots: false,
      infinite: true,
      centerMode: true,
      centerPadding: "15%",
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: false,
      accessibility: true,
      className: "innerslide"
    }

    const categorybg = {
      background: `${nodes[0].category.favoriteColor.hex}`
    }

    return (
      <div className={`${styles.colorelement} previewbox`}>
        <div className={styles.background} style={categorybg} />
        <div className={styles.nobackground} />
        <div className={styles.background2} style={categorybg} />
        <div className={styles.nobackground2} />
        <div className={styles.root}>
          <div className={styles.catname}>
            <p className={styles.catnametext}>{nodes[0].category.title}</p>
          </div>
          <div className={styles.innergrid}>
            <div className={styles.titlearea}>
              <p>{nodes[0].category.title}</p>
            </div>
            <div className={styles.spacerl} />
            <div className={styles.inner}>
              <div className={styles.bigpreview}>
                <BigPostPreview {...nodes[0]} />
              </div>
              <div className={styles.slider}>
                <Slider {...settings}>
                  {nodes &&
                    nodes.slice(1).map(node => (
                      <div key={node.id}>
                        <BlogPostPreview {...node} />
                      </div>
                    ))}
                </Slider>
              </div>
              <div className={styles.slidermobile}>
                <Slider {...mobilesettings}>
                  {nodes &&
                    nodes.slice(1).map(node => (
                      <div key={node.id}>
                        <BlogPostPreview {...node} />
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
            <div className={styles.spacerr} />
          </div>
        </div>
      </div>
    )
  }
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
}

export default BlogPostPreviewGrid
